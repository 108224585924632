/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/display-name */
/* eslint-disable jsx-a11y/heading-has-content */
import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import BlockContent from '@sanity/block-content-to-react';

const EventStyles = styled.div`
  background: white;
  padding: 1.5rem;
  margin: 0 auto 32px auto;
  box-shadow: 5px 5px 0 0 rgba(0, 0, 0, 0.6);
`;

const EventTitle = styled.div`
  border-bottom: dotted 2px;
  padding-bottom: 10px;
`;

export default function EventPage({ data: { event } }) {
  const overrides = {
    h2: (props) => <h2 className="title" {...props} />,
    normal: (props) => <p className="content" {...props} />,
  };

  const serializers = {
    types: {
      block: (props) =>
        // Check if we have an override for the “style”
        overrides[props.node.style]
          ? // if so, call the function and pass in the children, ignoring
            // the other unnecessary props
            overrides[props.node.style]({ children: props.children })
          : // otherwise, fallback to the provided default with all props
            BlockContent.defaultSerializers.types.block(props),
    },
  };
  const title = event.eventTitle
    ? event.eventTitle
    : event.eventArtists.map((artist) => artist.artistName).join(', ');
  return (
    <EventStyles>
      <EventTitle>
        <h2>{title}</h2>
        {event.eventInfo && <p>{event.eventInfo}</p>}
      </EventTitle>
      <BlockContent blocks={event._rawEventInfo} serializers={serializers} />
    </EventStyles>
  );
}

export const query = graphql`
  query($slug: String!) {
    event: sanityEvent(slug: { current: { eq: $slug } }) {
      eventTitle
      id
      eventTitle
      _rawEventInfo
      eventEndDate
      eventStartDate
      eventArtists {
        artistName
      }
      location {
        venueName
      }
    }
  }
`;
